<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Hub Name</th>
          <th>Hub Code</th>
          <th>Hub Address</th>
          <th>Action</th>
        </tr>
        <tr v-for="(hub,i) in hubs" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character Limit 30" :maxlength="max"
                v-model="hub.name"
                :disabled="!hub.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character Limit 30" :maxlength="max"
                v-model="hub.hub_code"
                :disabled="!hub.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <textarea class="two_line" v-model="hub.hub_address" :disabled="!hub.is_edit_mode" placeholder="Address Character Limit 60" :maxlength="max2"></textarea>
            </div>
          </td>
          <td class>
            <a class="table_action_btn" title="DELETE" @click="deleteHub(hub.id)">DELETE</a>
            <a
              class="table_action_btn"
              title="CANCEL"
              v-if="hub.is_edit_mode"
              @click="cancelHub()"
            >CANCEL</a>
            <a
              class="table_action_btn"
              title="UPDATE"
              v-if="hub.is_edit_mode"
              @click="updateHub(hub)"
            >UPDATE</a>
            <a
              class="table_action_btn"
              title="EDIT"
              v-if="!hub.is_edit_mode"
              @click="hub.is_edit_mode = true"
            >EDIT</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hub",
  components: {},
  data() {
    return {
      max:30,
      max2:60,
      makeEditable: false,
      cancelEditable: true,
      hubs: []
    };
  },
  created() {
    this.fetchHub();
  },
  methods: {
    fetchHub() {
      this.axios
        .get("/api/hub/")
        .then(response => {
          console.log(response);
          this.hubs = response.data.hubs.map(element => {
            element.is_edit_mode = false;
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateHub(data) {
      this.axios
        .put("/api/hub/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteHub(id) {
      if (confirm("Are you sure you want to delete this hub?")) {
        this.axios
          .delete("/api/hub/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchHub();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelHub(){
      this.fetchHub();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>
<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Hub Name Character Limit 30" class  v-model="hub.name":maxlength="max"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Hub Code Character Limit 30" class  v-model="hub.hub_code" :maxlength="max"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub Address
                      <span>*</span>
                    </label>
                    <textarea class="two_line" placeholder="Address Character Limit 60" v-model="hub.hub_address" :maxlength="max2"></textarea>
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </section>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hub",
  data(){
    return {
      max:30,
      max2:60,
      hub:{
        name : "",
        hub_code:"",
        hub_address:""
      }
    }
  },
  methods: {
    submit(){
      this.axios.post("/api/hub/",this.hub)
        .then(response => {
          this.toast.success(response.data.msg);
            this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
</style>